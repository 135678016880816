.card-grid {
  padding: 8px;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  // grid-gap: 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  height: calc(100% - 80px);
  background-color: #eee;
  flex: 1;
}

.card-grid .product,
.card-grid .recommended-product {
  margin: 8px;
}
