/* CSS for the table */
table {
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 0;
    color: black;
        background-color: whitesmoke;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: plum;
    font-weight: bold;
}


@media (max-width: 40em) {
    .css-1v7wabx-MuiPaper-root-MuiCard-root {
        min-width: 140px !important;
        width: 160px !important;
        height: 240px !important;
    }
}
tbody tr:hover {
    color: black;
    background-color: plum;
}