body {
  margin: 0px !important;
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100%;
}

.drag-container {
  position: absolute;
  top: 20px; // Initial position from top
  left: 20px; // Initial position from left
  cursor: move;
}
.search-container {
  display: flex;
  padding: 2em;
  background: linear-gradient(
    90deg,
    rgb(70 6 122 / 61%) 0%,
    rgb(152 103 192) 0%,
    rgb(237 151 218) 100%,
    rgba(0, 212, 255, 1) 100%
  );

  .search-input {
    flex: 1;
  }
}

.center-panel {
  height: 100%;
  display: flex;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
